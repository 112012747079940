<template>
    <div class="page-admin-site">
        <div class="table-wr border-radius overflow-hidden bg-white">
            <div>
                <table class="table border-b table-fixed">
                    <thead>
                        <th 
                            v-for="item in columns"
                            :key="item.title"
                            class="truncate ok whitespace-no-wrap"
                        >{{ item.title }}</th>
                    </thead>

                    <tbody v-for="item in sites" :key="item.id">
                        <tr>
                            <td :title="item.id">
                                {{ item.id }}
                            </td>
                            <td class="truncate ok whitespace-no-wrap" :title="item.name">
                                {{ item.name }}
                            </td>

                            <td class="truncate ok whitespace-no-wrap" :title="organization(item)">
                                {{ organization(item) }}
                            </td>

                            <td class="truncate ok whitespace-no-wrap" :title="`${ item.users_count } users`">
                                {{ item.users_count }} users
                            </td>

                            <td class="text-right">
                                <button @click="editSite(item.id)" class="text-gray-800">
                                    <svg-importer icon-name="icons/edit" :key="`settings-sites-edit_${item.id}`" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SettingsSites",

        metaInfo() {
            return { title: "Settings sites" };
        },

        data: () => ({
            columns: [
                {
                    title: "ID",
                    key: "id",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Name",
                    key: "name",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2"
                },
                {
                    title: "Organization",
                    key: "organization-name",
                    toggled: true,
                    sortable: false,
                    width: "w-1/2"
                },
                {
                    title: "Users",
                    key: "users_count",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2"
                },
                {
                    title: "",
                    key: "edit",
                    toggled: true,
                    sortable: false,
                    width: "w-1/3"
                }
            ]
        }),

        computed: {
            sites() {
                return this.$store.getters["auth/sites"];
            },
        },

        methods: {
            editSite(id) {
                this.$router.push({
                    name: "settings.sites.edit",
                    params: {
                        id: id
                    }
                });
            },

            organization(item) {
                return item?.organization?.name
            }
        }
    };
</script>
